import React, { useState,useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate, useLocation , Link } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import {reactLocalStorage} from 'reactjs-localstorage';
import useFetch from '../../useFetch';
import Spinner from 'react-bootstrap/Spinner';
import apiRequest from '../../ApiRequest'
const ShowAllInvestors = () => {
    let email = reactLocalStorage.get('email')
    const location = useLocation()
    const company = location.state.company
    const deal = location.state.deal
    const [startDate, setStartDate] = useState(0);
    const [ActiveDeal, setActiveDeal] = useState(false);
    const [index, setIndex] = useState(0);
    const [propertyVariables, setPropertyVariables] = useState([])
    const [all_diligences_new, setall_diligences_new] = useState([])

    const mystyle = {
        color: "white",
        backgroundColor: "#f4f4f4",
        padding: "10px",
      };

      let cookie = reactLocalStorage.get('cookie')
      const requestOptions = {
          method: 'GET',
          headers: { 
              'x-access-token': cookie},
            };
            const { data: all_deal_investors, error, isPending} = useFetch(`/investor/all_deal_investors/${company.id}` , requestOptions) 
  

    const activateProperty = async e => {
      let info = startDate
      let a = await apiRequest('Post',info,`/property/activate/${company.id}/${deal.id}/${e.target.id}`)

    
        }
    return ( 
        <>
        <div style={mystyle}>
    <Row>
        <Col></Col>
        <Col xs={9}>    <Card className="text-center" bg="dark"key="Info" text="white">
      <Card.Body>
        <Card.Title><h2>Here is a list of all of your investors</h2></Card.Title>
        <Card.Text>
        </Card.Text>

</Card.Body>
     
    </Card>
</Col>
        <Col></Col>
        <h1></h1>
       
        </Row>
        </div>  
        <Row>
        <Col md={{ span: 10, offset: 1 }}>  
      

        <Accordion defaultActiveKey={['0']} alwaysOpen>
        {all_deal_investors && Object.entries(all_deal_investors).map( ([email, all_info],index) => {
                    return (
                <Accordion.Item eventKey={index}>

                <Accordion.Header>{all_info.info.name} {email}</Accordion.Header>
                <Accordion.Body>
                    {console.log(all_info)}



                    <Accordion defaultActiveKey={['0']} alwaysOpen>
        {all_info.investments && all_info.investments.map((deal_info, i) => {
                    return (
                <Accordion.Item eventKey={i}>

                <Accordion.Header>{deal_info.deal_id} - {deal_info.deal_name}</Accordion.Header>
                <Accordion.Body>
                Date Investment Started - {deal_info.date_started}
                Amount Invested - {deal_info.amount_invested}
                    {console.log(deal_info.date_started)}
      
                    {deal_info.profits && deal_info.profits.map((profit, i) => {
                    if (profit.payed_distributions > .001){return (
                <h4 eventKey={i}>

              { profit.payed_distributions && <>${ profit.payed_distributions.toLocaleString()}</>}
              { profit.distribution_date && <>{ profit.distribution_date.toLocaleString()}</>}

              </h4>
                    )}
      })} 
 

                </Accordion.Body>
              </Accordion.Item>
                    )
      })} 


    </Accordion>

 

                </Accordion.Body>
              </Accordion.Item>
                    )
      })} 


    </Accordion>
    </Col>
    </Row>

        </>
     );
    
}
 
export default ShowAllInvestors;